import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import AppInitWrapper from './components/AppInitWrapper';
import './i18n';
import Spinner from '@amzn/awsui-components-react/polaris/spinner';
import { BrowserRouter } from 'react-router-dom';
import Amplify, { Auth } from 'aws-amplify';
import '@amzn/awsui-global-styles/polaris.css';
import { NotificationProvider } from './hooks/notifications';

/**
 * Amplify configuration
 */

const callbackURL = window.location.origin;
const config = {
  oauth: {
    domain: 'acstools-sitetesting-prod.auth.us-east-1.amazoncognito.com',
    scope: ['openid'],
    redirectSignIn: callbackURL,
    redirectSignOut: callbackURL,
    responseType: 'code',
  },
  // Cognito options
  aws_cognito_identity_pool_id: 'us-east-1:0cb839bd-1e0a-4723-abd9-fbd599da706c',
  aws_cognito_region: 'us-east-1',
  aws_user_pools_id: 'us-east-1_MuEhJAGe5',
  aws_user_pools_web_client_id: '5m4bn6qgnum2v5nijvj1ut7b39',
  // AppSync
  aws_appsync_graphqlEndpoint: 'https://hkt4dptcerfjjjroujkwywos64.appsync-api.us-east-1.amazonaws.com/graphql',
  aws_appsync_region: 'us-east-1',
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  API: {
    graphql_endpoint: 'https://hkt4dptcerfjjjroujkwywos64.appsync-api.us-east-1.amazonaws.com/graphql',
    graphql_headers: async () => ({
      Authorization: (await Auth.currentAuthenticatedUser())
        .getSignInUserSession()
        .getIdToken()
        .getJwtToken(),
      AuthUser: (await Auth.currentAuthenticatedUser()).getUsername(),
    }),
  },
};

Amplify.configure(config);

// The Suspense component causes React to display the fallback KatSpinner until
// the i18n translation strings have been loaded.

ReactDOM.render(
  <React.StrictMode>
    <AppInitWrapper>
      <NotificationProvider>
        <React.Suspense fallback={<Spinner size="large" />}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </React.Suspense>
      </NotificationProvider>
    </AppInitWrapper>
  </React.StrictMode>,
  document.getElementById('root')
);
